import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import { Cards, CategoryCard } from '@accodeing/gatsby-theme-switsbake'

const Category = ({ category, image }) => (
  <CategoryCard title={category.name} linkTo={'/sortiment/' + category.id}>
    <GatsbyImage
      image={image.childImageSharp.gatsbyImageData}
      alt={category.name}
    />
  </CategoryCard>
)

const categoryImage = (images, categoryId) =>
  images.filter((image) => image.name === categoryId)[0]

const Sortiment = ({ pageContext, data }) => {
  return (
    <Layout
      pageTitle='Sortiment'
      backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
    >
      <oma-grid-row class='row'>
        <section className='section section--with-background'>
          <h1 className='page__heading'>Sortiment</h1>
          <p className='section__text section__text--margin-top'>
            Läs gärna mer om våra produkter genom att klicka på någon av
            kategorierna nedan.
          </p>
          <p className='buy-button'>
            <a
              href='https://www.matspar.se/varumarke/hagges'
              target='_blank'
              rel='noreferrer'
              className='buy-button__link'
            >
              Köp våra produkter
            </a>
            <span className='buy-button__disclaimer'>
              I samarbete med Matspar
            </span>
          </p>
        </section>
        <section className='section'>
          <Cards>
            {pageContext.categories.map((category) => (
              <Category
                category={category}
                image={categoryImage(data.images.nodes, category.id)}
                key={category.name}
              />
            ))}
          </Cards>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  {
    imageBackground: file(relativePath: { eq: "cakes.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "categories" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(width: 520, quality: 60, layout: CONSTRAINED)
        }
      }
    }
  }
`

export default Sortiment
